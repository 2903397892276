<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Reliability of Experimental Measurements - Report Sheet</h2>

      <p class="mb-5">
        Please use the data collected from the experiment to fill in the six data tables below for
        different combinations of balance and glassware.
      </p>

      <v-simple-table v-for="table in dataTables" class="mb-8" :key="table.description">
        <thead>
          <tr class="mb-n3">
            <td colspan="4" style="font-weight: bold; font-size: 1.1rem">
              {{ table.description }}
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold"></td>
            <td style="font-weight: bold; text-align: center">Mass (g)</td>
            <td style="font-weight: bold; text-align: center">Volume (mL)</td>
            <td style="font-weight: bold; text-align: center">Density (g/mL)</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Trial 1</td>
            <td class="py-2 centered-input">
              <calculation-input
                v-model="inputs[table.massT1]"
                dense
                outlined
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 centered-input">
              <calculation-input
                v-model="inputs[table.volumeT1]"
                dense
                outlined
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 centered-input">
              <calculation-input
                v-model="inputs[table.densityT1]"
                dense
                outlined
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Trial 2</td>
            <td class="py-2 centered-input">
              <calculation-input
                v-model="inputs[table.massT2]"
                dense
                outlined
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 centered-input">
              <calculation-input
                v-model="inputs[table.volumeT2]"
                dense
                outlined
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 centered-input">
              <calculation-input
                v-model="inputs[table.densityT2]"
                dense
                outlined
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Trial 3</td>
            <td class="py-2 centered-input">
              <calculation-input
                v-model="inputs[table.massT3]"
                dense
                outlined
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 centered-input">
              <calculation-input
                v-model="inputs[table.volumeT3]"
                dense
                outlined
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 centered-input">
              <calculation-input
                v-model="inputs[table.densityT3]"
                dense
                outlined
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">Average</td>
            <td class="py-2 centered-input">
              <calculation-input dense outlined placeholder="---" :disabled="true" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input dense outlined placeholder="---" :disabled="true" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input
                v-model="inputs[table.densityAvg]"
                dense
                outlined
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">St. Dev.</td>
            <td class="py-2 centered-input">
              <calculation-input dense outlined placeholder="---" :disabled="true" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input dense outlined placeholder="---" :disabled="true" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input
                v-model="inputs[table.densityStDev]"
                dense
                outlined
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">Percent Error (%)</td>
            <td class="py-2 centered-input">
              <calculation-input dense outlined placeholder="---" :disabled="true" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input dense outlined placeholder="---" :disabled="true" />
            </td>
            <td class="py-2 centered-input">
              <calculation-input
                v-model="inputs[table.densityPctError]"
                dense
                outlined
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import ChemicalSymbolInput from '@/tasks/components/inputs/ChemicalSymbolInput.vue';

export default {
  name: 'ReliabilityOfMeasurementsReportSheet1',
  components: {ChemicalSymbolInput, ChemicalNotationInput, CalculationInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        B1GW1MassT1: null,
        B1GW1VolumeT1: null,
        B1GW1DensityT1: null,

        B1GW1MassT2: null,
        B1GW1VolumeT2: null,
        B1GW1DensityT2: null,

        B1GW1MassT3: null,
        B1GW1VolumeT3: null,
        B1GW1DensityT3: null,

        B1GW1DensityAvg: null,
        B1GW1DensityStDev: null,
        B1GW1DensityPctError: null,

        B1GW2MassT1: null,
        B1GW2VolumeT1: null,
        B1GW2DensityT1: null,

        B1GW2MassT2: null,
        B1GW2VolumeT2: null,
        B1GW2DensityT2: null,

        B1GW2MassT3: null,
        B1GW2VolumeT3: null,
        B1GW2DensityT3: null,

        B1GW2DensityAvg: null,
        B1GW2DensityStDev: null,
        B1GW2DensityPctError: null,

        B1GW3MassT1: null,
        B1GW3VolumeT1: null,
        B1GW3DensityT1: null,

        B1GW3MassT2: null,
        B1GW3VolumeT2: null,
        B1GW3DensityT2: null,

        B1GW3MassT3: null,
        B1GW3VolumeT3: null,
        B1GW3DensityT3: null,

        B1GW3DensityAvg: null,
        B1GW3DensityStDev: null,
        B1GW3DensityPctError: null,

        B2GW1MassT1: null,
        B2GW1VolumeT1: null,
        B2GW1DensityT1: null,

        B2GW1MassT2: null,
        B2GW1VolumeT2: null,
        B2GW1DensityT2: null,

        B2GW1MassT3: null,
        B2GW1VolumeT3: null,
        B2GW1DensityT3: null,

        B2GW1DensityAvg: null,
        B2GW1DensityStDev: null,
        B2GW1DensityPctError: null,

        B2GW2MassT1: null,
        B2GW2VolumeT1: null,
        B2GW2DensityT1: null,

        B2GW2MassT2: null,
        B2GW2VolumeT2: null,
        B2GW2DensityT2: null,

        B2GW2MassT3: null,
        B2GW2VolumeT3: null,
        B2GW2DensityT3: null,

        B2GW2DensityAvg: null,
        B2GW2DensityStDev: null,
        B2GW2DensityPctError: null,

        B2GW3MassT1: null,
        B2GW3VolumeT1: null,
        B2GW3DensityT1: null,

        B2GW3MassT2: null,
        B2GW3VolumeT2: null,
        B2GW3DensityT2: null,

        B2GW3MassT3: null,
        B2GW3VolumeT3: null,
        B2GW3DensityT3: null,

        B2GW3DensityAvg: null,
        B2GW3DensityStDev: null,
        B2GW3DensityPctError: null,
      },
      dataTables: [
        {
          description: 'Table 1. Pan Balance + 50 mL beaker',
          massT1: 'B1GW1MassT1',
          volumeT1: 'B1GW1VolumeT1',
          densityT1: 'B1GW1DensityT1',
          massT2: 'B1GW1MassT2',
          volumeT2: 'B1GW1VolumeT2',
          densityT2: 'B1GW1DensityT2',
          massT3: 'B1GW1MassT3',
          volumeT3: 'B1GW1VolumeT3',
          densityT3: 'B1GW1DensityT3',
          densityAvg: 'B1GW1DensityAvg',
          densityStDev: 'B1GW1DensityStDev',
          densityPctError: 'B1GW1DensityPctError',
        },
        {
          description: 'Table 2. Pan Balance + 25 mL grad cylinder',
          massT1: 'B1GW2MassT1',
          volumeT1: 'B1GW2VolumeT1',
          densityT1: 'B1GW2DensityT1',
          massT2: 'B1GW2MassT2',
          volumeT2: 'B1GW2VolumeT2',
          densityT2: 'B1GW2DensityT2',
          massT3: 'B1GW2MassT3',
          volumeT3: 'B1GW2VolumeT3',
          densityT3: 'B1GW2DensityT3',
          densityAvg: 'B1GW2DensityAvg',
          densityStDev: 'B1GW2DensityStDev',
          densityPctError: 'B1GW2DensityPctError',
        },
        {
          description: 'Table 3. Pan Balance + 10 mL vol pipette',
          massT1: 'B1GW3MassT1',
          volumeT1: 'B1GW3VolumeT1',
          densityT1: 'B1GW3DensityT1',
          massT2: 'B1GW3MassT2',
          volumeT2: 'B1GW3VolumeT2',
          densityT2: 'B1GW3DensityT2',
          massT3: 'B1GW3MassT3',
          volumeT3: 'B1GW3VolumeT3',
          densityT3: 'B1GW3DensityT3',
          densityAvg: 'B1GW3DensityAvg',
          densityStDev: 'B1GW3DensityStDev',
          densityPctError: 'B1GW3DensityPctError',
        },
        {
          description: 'Table 4. Analytical Balance + 50 mL beaker',
          massT1: 'B2GW1MassT1',
          volumeT1: 'B2GW1VolumeT1',
          densityT1: 'B2GW1DensityT1',
          massT2: 'B2GW1MassT2',
          volumeT2: 'B2GW1VolumeT2',
          densityT2: 'B2GW1DensityT2',
          massT3: 'B2GW1MassT3',
          volumeT3: 'B2GW1VolumeT3',
          densityT3: 'B2GW1DensityT3',
          densityAvg: 'B2GW1DensityAvg',
          densityStDev: 'B2GW1DensityStDev',
          densityPctError: 'B2GW1DensityPctError',
        },
        {
          description: 'Table 5. Analytical Balance + 25 mL grad cylinder',
          massT1: 'B2GW2MassT1',
          volumeT1: 'B2GW2VolumeT1',
          densityT1: 'B2GW2DensityT1',
          massT2: 'B2GW2MassT2',
          volumeT2: 'B2GW2VolumeT2',
          densityT2: 'B2GW2DensityT2',
          massT3: 'B2GW2MassT3',
          volumeT3: 'B2GW2VolumeT3',
          densityT3: 'B2GW2DensityT3',
          densityAvg: 'B2GW2DensityAvg',
          densityStDev: 'B2GW2DensityStDev',
          densityPctError: 'B2GW2DensityPctError',
        },
        {
          description: 'Table 6. Analytical Balance + 10 mL vol pipette',
          massT1: 'B2GW3MassT1',
          volumeT1: 'B2GW3VolumeT1',
          densityT1: 'B2GW3DensityT1',
          massT2: 'B2GW3MassT2',
          volumeT2: 'B2GW3VolumeT2',
          densityT2: 'B2GW3DensityT2',
          massT3: 'B2GW3MassT3',
          volumeT3: 'B2GW3VolumeT3',
          densityT3: 'B2GW3DensityT3',
          densityAvg: 'B2GW3DensityAvg',
          densityStDev: 'B2GW3DensityStDev',
          densityPctError: 'B2GW3DensityPctError',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
